import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"
import useSiteMetadata from "../hooks/use-site-metadata"

type AnalyticsProps = { }

const Analytics = ({}: AnalyticsProps) => {
  const { tealiumUrlProd, tealiumUrlQa, environment } = useSiteMetadata()

  if (!tealiumUrlProd) {
    return null
  }

  const isProduction = environment?.endsWith(`-main`) || environment?.includes(`-split-`)
  const envSpecificTealiumUrl = isProduction ? tealiumUrlProd : tealiumUrlQa

  return (
    <Helmet>
      <script type="text/javascript">{`
        const utag_data = {"branch" : "${environment}"};

        window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
        window.utag_cfg_ovrd.noview = true;

        (function(a,b,c,d){
          a='${envSpecificTealiumUrl}';
          b=document;c='script';d=b.createElement(c);
          d.onload=function() { b.dispatchEvent(new Event("utag-loaded")); };
          d.src=a;d.type='text/java'+c;d.async=true;
          a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)
        })();

        document.addEventListener("utag-loaded", () => {
          
        });
      `}</script>
    </Helmet>
  )
}

export default Analytics
