import React from "react"
import loadable from '@loadable/component'
import { SectionType } from "../types"

const EmptyHero = loadable(() => import("./sections/heros/empty-hero"))
const FormHero = loadable(() => import("./sections/heros/form-hero"))
const FormHero2 = loadable(() => import("./sections/heros/form-hero-2"))
const CardsSection = loadable(() => import("./sections/cards-section"))
const FaqSection = loadable(() => import("./sections/faqs/faq-section"))
const FormSection = loadable(() => import("./sections/form-section"))
const SimpleLogoCloudSection = loadable(() => import("./sections/logo-clouds/simple-1"))
const SimpleGenericSection = loadable(() => import("./sections/generic/simple-1"))
const SplitImageGenericSection = loadable(() => import("./sections/generic/split-image"))
const SplitImageGeneric2Section = loadable(() => import("./sections/generic/split-image-2"))
const BasicIFrameSection = loadable(() => import("./sections/iframes/basic-iframe"))
const SplitIFrameSection = loadable(() => import("./sections/iframes/split-iframe"))
const SplitIFrame2Section = loadable(() => import("./sections/iframes/split-iframe-2"))
const TestimonialCards3xSection = loadable(() => import("./sections/testimonials/cards-3x"))
const TestimonialSliderSection = loadable(() => import("./sections/testimonials/slider"))
const TestimonialSplitSliderSection = loadable(() => import("./sections/testimonials/split-slider"))
const SpacerSection = loadable(() => import("./sections/spacer-section"))

type LayoutProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const Section = ({ section, ...props }: LayoutProps) => {
  switch (section.templateId) {
    case `empty-hero`:
      return <EmptyHero section={section} {...props} />
    case `form-hero`:
      return <FormHero section={section} {...props} />
    case `form-hero-2`:
      return <FormHero2 section={section} {...props} />
    case `cards-section`:
      return <CardsSection section={section} {...props} />
    case `faq-section`:
      return <FaqSection section={section} {...props} />
    case `form-section`:
      return <FormSection section={section} {...props} />
    case `generic-section`:
      return <SimpleGenericSection section={section} {...props} />
    case `split-image-generic-section`:
      return <SplitImageGenericSection section={section} {...props} />
    case `split-image-generic-2-section`:
      return <SplitImageGeneric2Section section={section} {...props} />
    case `spacer-section`:
      return <SpacerSection section={section} {...props} />
    case `simple-logo-cloud-section`:
      return <SimpleLogoCloudSection section={section} {...props} />
    case `basic-iframe-section`:
      return <BasicIFrameSection section={section} {...props} />
    case `split-iframe-section`:
      return <SplitIFrameSection section={section} {...props} />
    case `split-iframe-2-section`:
      return <SplitIFrame2Section section={section} {...props} />
    case `testimonial-cards-3x-section`:
      return <TestimonialCards3xSection section={section} {...props} />
    case `testimonial-slider-section`:
      return <TestimonialSliderSection section={section} {...props} />
    case `testimonial-split-slider-section`:
      return <TestimonialSplitSliderSection section={section} {...props} />
    default:
      return <SimpleGenericSection section={section} {...props} />
  }
}

export default Section
