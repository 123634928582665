import React from "react"
import { graphql } from "gatsby"
import { PageType } from "../types"
import Landing1 from "./pages/landing1"
import Basic from "./pages/basic"

export const query = graphql`
  query($slug: String!) {
    page(slug: { eq: $slug }) {
      slug
      templateId
      seo {
        title
        description
      }
      header {
        templateId
        logo
        logoLink
        logoHeightLg
        logoHeightSm
        links {
          url
          name
          icon
          class
        }
        advanced {
          class
        }
      }
      footer {
        templateId
        logo
        advanced {
          class
        }
      }
      sections {
        templateId
        blurb
        blurb2
        advanced {
          backgroundImageLg
          backgroundImageSm
          backgroundHeight
          backgroundPosition
          backgroundFit
          backgroundColor
          background2ImageLg
          background2ImageSm
          background2Height
          background2Position
          background2Fit
          background2Color
          class
          class2
        }
        cards {
          title
          blurb
          footer
          link
          class
          minHeight
        }
        qa {
          q
          a
        }
        links {
          url
          name
          icon
          class
        }
        form {
          templateId
          destination
          submitText
        }
        iframe {
          url
          title
        }
        testimonials {
          name
          location
          testimonial
          image
        }
      }
      body
    }
  }
`

type PageProps = {
  data: {
    page: PageType
  }
  [key: string]: any
}

export default function Page({ data: { page }, ...props }: PageProps) {
  return page.templateId === `landing1` ? <Landing1 page={page} {...props} /> : <Basic page={page} {...props} />
}